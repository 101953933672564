export const appconfig = {
  mode:"qa",
  requiredAccessGroup: "68cc6ffb-bd0e-4d8b-a326-90ccf51fec7c",
  apiUrl: 'https://qa.support-portal-backend.vmsak-sky.no/api',
  msalConfig: {
    auth: {
      clientId: `9886dec2-f97e-47a1-ae11-3d3a58575383`,
      authority:
        `https://login.microsoftonline.com/6dfcc7f0-98d9-48b6-9251-b8a2bbdbfe37`,
        redirectUri: `https://qa.supportportal.vmsak-sky.no`,
        postLogoutRedirectUri: `https://qa.supportportal.vmsak-sky.no`
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    },
  },
  scopes: [
    "openid",
    "email",
    "profile",
    "offline_access",
    "User.Read",
    "Mail.Read",
    "Files.Read.All",
    "Files.Read",
    "Files.ReadWrite",
  ],
};
